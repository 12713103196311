import * as React from 'react';
import "../../styles/components/_successPopup.scss";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {DialogContent, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Glow from '@mui/material/Grow';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Glow ref={ref} {...props} />;
});

export interface SuccessPopupProps {
    open: boolean;
    onClose: any;
    theme: any;
    popupType: string;
}

const SuccessPopup: React.FC<SuccessPopupProps> = (props: SuccessPopupProps) => {
    const {onClose, open, theme, popupType} = props;
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog TransitionComponent={Transition}
            className={`${theme === 1 ? "theme_white_popup" : theme === 2 ? "theme_black_popup" : "theme_blue_popup"}  ${popupType === "BUY" ? "buy_theme" : "sell_theme"}`}
            onClose={handleClose} open={open}>
            <DialogTitle className="buy_sell_popup__head">{onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 5,
                        top: 5,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}</DialogTitle>
            <DialogContent className="order-preview__content">
                <div className='popup'>
                    <div className='popup_wrapper'>
                        <div className='first-row'>
                            <div className='popup-icon'>
                                {popupType==="BUY" ? (
                                    <img src="/Images/buy-success.svg" alt="tesla"/>
                                ): (
                                    <img src="/Images/sell-success.svg" alt="tesla"/>
                                )}
                            </div>
                            <h4>Your order has been received successfully</h4>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default SuccessPopup;