import * as React from "react";
import "../../styles/components/_buySellPopup.scss";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogContent, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useClient } from "../clientselection/useClient";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchAllPortfolios,
  fetchAllPortfoliosForTrading,
  fetchAvailableQuantity,
  fetchAvailableQuantityRepo,
  fetchSidePortfolioSummary,
  fetchValidateQuantity,
  validateBuyTrade,
} from "../../services/portfolioService";
import { PortfolioDTO } from "../../types/service";
import {
  fetchCalculateConsideration,
  fetchCounterParty,
  fetchProductLatestPrice,
} from "../../services/marketServices";
import moment from "moment";
import {
  numberWithCommas,
  numberWithCommasAndDecimal,
} from "../../utils/helper";
import ReactTooltip from "react-tooltip";
import Glow from "@mui/material/Grow";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Glow ref={ref} {...props} />;
});

export interface BuySellPopupProps {
  open: boolean;
  onClose: any;
  theme: any;
  onSubmit: any;
  setPopupType: any;
  popupType: any;
  category: number;
  row: any;
  clearFilter: boolean;
  currencySymbol: string;
  selectedDate: string;
}

const SETTLEMENT_TYPE = [
  { label: "T0", value: "T0" },
  { label: "T1", value: "T1" },
  { label: "T2", value: "T2" },
  { label: "T3", value: "T3" },
];

const BuySellPopup: React.FC<BuySellPopupProps> = (
  props: BuySellPopupProps
) => {
  const {
    onClose,
    setPopupType,
    open,
    theme,
    popupType,
    onSubmit,
    row,
    selectedDate,
    category,
  } = props;
  const [formData, setFormData] = useState<any>({
    quantity: "",
    amount: "",
    price: "",
    priceOrYield: "PRICE",
    quantityOrAmount: "Amount",
    amountToDisplay: "",
    quantityToDisplay: "",
    yieldToDisplay: "",
    priceToDisplay: "",
    yield: "",
    settlement: "T0",
    portfolio: "",
    consideration: "",
    indicativeConsideration: "",
    counter_party: "",
    order_type: "MARKET",
    order_validity: "GTC",
    transactionFees: "",
  });
  const [portfolioList, setPortfolioList] = useState<any>([]);
  const [tempData, setTemp] = useState<any>({});
  const [counterPartyTitle, setCounterPartyTitle] = useState<any>("");
  const [counterParty, setCounterParty] = useState<any>([]);
  const [apiData, setData] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [availableQuantity, setAvailableQuantity] = useState<any>(0);
  const [productLatestPrice, setProductLatestPrice] = useState<any>({});
  const [validateBuy, setValidateBuy] = useState<any>(false);

  const { selectedClient } = useClient();

  useEffect(() => {
    if (popupType === "BUY" || row.orderOfferType === "REPO") {
      fetchAllPortfolios().then((res: PortfolioDTO[]) => {
        const list: any = [];
        const rowPortfolioIds = row?.portfolioUUID ? [row?.portfolioUUID] : [];
        row?.children?.map((child: any) => {
          if (child?.portfolioUUID) {
            rowPortfolioIds?.push(child?.portfolioUUID);
          }
        });
        if (popupType === "SELL" && row.orderOfferType !== "REPO") {
          res?.length > 0 &&
            res?.forEach((e) => {
              if (rowPortfolioIds?.includes(e.id)) {
                list.push({ key: e.id, value: e.name });
              }
            });
        } else {
          res?.length > 0 &&
            res?.forEach((e) => {
              list.push({ key: e.id, value: e.name });
            });
        }
        setPortfolioList(list);
      });
    }
  }, [selectedClient, popupType, row]);

  useEffect(() => {
    if (row && formData && popupType === "SELL" && row.orderOfferType !== "REPO") {
      fetchAllPortfoliosForTrading(
        formData?.portfolio?.key,
        row?.securityUUID,
        {}
      ).then((res: any) => {
        const list: any = [];

        res?.length > 0 &&
          res[0]?.children?.forEach((e) => {
            list.push({ key: e.portfolioUUID, value: e.portfolioName });
          });

        setPortfolioList(list);
      });
    }
  }, [selectedClient, popupType, row]);

  const handleClose = () => {
    handleClearFilter();
    onClose();
  };

  useEffect(() => {
    handleClearFilter();
  }, [row, formData?.order_type]);

  useEffect(() => {
    if (formData?.portfolio) {
      let clientId: any = sessionStorage.getItem("clientId");
      fetchSidePortfolioSummary(
        clientId,
        selectedDate
          ? moment(selectedDate).format("DD-MM-YYYY")
          : moment().format("DD-MM-YYYY"),
        formData?.portfolio?.key
      )
        .then((res) => {
          setData(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [formData?.portfolio, selectedDate]);

  useEffect(() => {
    if (
      popupType === "SELL" &&
      row.orderOfferType === "REPO" &&
      formData?.portfolio
    ) {
      fetchAvailableQuantityRepo(
        formData?.portfolio?.key,
        row?.currency?.code
      )?.then((res) => {
        const list: any = [];
        res?.length > 0 &&
          res?.forEach((e: any) => {
            if (e.assetClass == "BOND" || e.assetClass == "BILL") {
              list.push({ key: e.securityUUID, value: e.bsSecurityMediumId });
            }
          });
        setCounterParty(list);
        setAvailableQuantity(res?.quantity);
      });
    } else if (
      popupType === "SELL" &&
      formData?.portfolio &&
      row?.securityUUID
    ) {
      fetchAvailableQuantity(
        row?.securityUUID,
        formData?.portfolio?.key,
        formData?.counter_party?.key
      )?.then((res) => {
        setAvailableQuantity(res?.quantity);
      });
    }
  }, [formData?.portfolio, formData?.counter_party, popupType, row]);

  useEffect(() => {
    if (
      popupType === "BUY" &&
      formData.portfolio &&
      counterParty.length > 0 &&
      formData?.settlement &&
      formData.amount != "" &&
      row.orderOfferType === "REVERSE_REPO"
    ) {
      let clientId: any = sessionStorage.getItem("clientId");
      fetchValidateQuantity(
        clientId,
        formData?.portfolio?.key,
        row.id,
        formData.amount,
        ""
      ).then((res) => {
        if (!res) {
          toast.error("Portfolio doesn't have enough balance.");
          setValidateBuy(res);
        } else {
          setValidateBuy(res);
          setIsDisabled(false);
        }
      });
    }

    if (
      popupType === "SELL" &&
      formData.portfolio &&
      formData.counter_party &&
      formData?.settlement &&
      formData.amount != "" &&
      row.orderOfferType === "REPO"
    ) {
      let clientId: any = sessionStorage.getItem("clientId");
      fetchValidateQuantity(
        clientId,
        formData?.portfolio?.key,
        row.id,
        formData.amount,
        formData.counter_party
      ).then((res) => {
        if (res.length > 0) {
          toast.error(res[0].message);
          setValidateBuy(res);
        } else {
          setValidateBuy(res);
          setIsDisabled(false);
        }
      });
    }
  }, [row, formData]);

  const handleClearFilter = () => {
    setFormData({
      quantity: "",
      amount: "",
      price: "",
      yield: "",
      settlement: "",
      portfolio: "",
      consideration: "",
      yieldToDisplay: "",
      priceToDisplay: "",
      counter_party: "",
      priceOrYield: "PRICE",
      quantityOrAmount: "Amount",
      order_type: formData?.order_type,
      order_validity: "GTC",
      transactionFees: "",
    });
  };

  useEffect(() => {
    if (row.orderOfferType && row.orderOfferType !== "REPO") {
      fetchCounterParty(0, 100, "")
        .then((res) => {
          const list: any = [];
          res?.content?.length > 0 &&
            res?.content?.forEach((e: any) => {
              list.push({ key: e.id, value: e.alias });
            });
          setCounterParty(list);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [row.orderOfferType]);

  const handleChange = (
    event: SelectChangeEvent,
    name: string,
    assetClass?: string
  ) => {
    if (name === "portfolio") {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: event?.target?.value,
      }));
    } else if (assetClass === "BOND" && name === "price") {
      if (parseFloat(event.target.value) <= 999 || !event.target.value) {
        setFormData((prevState: any) => ({
          ...prevState,
          [name]: event.target.value,
        }));
      }
    } else if (popupType === "BUY" && name === "amount") {
      let value: number = 0;
      apiData?.currencyWiseBalance?.map((innerData: any) => {
        if (innerData?.currency?.symbol === row?.securityCurrency?.symbol) {
          value = parseFloat(innerData?.quantity);
        }
      });
      if (
        parseFloat(event.target.value) <= parseFloat(value) ||
        !event.target.value
      ) {
        setFormData((prevState: any) => ({
          ...prevState,
          [name]: event.target.value,
        }));
      }
    } else if (popupType === "SELL" && name === "quantity") {
      if (
        parseInt(event.target.value?.replaceAll(",", "")) <=
          parseInt(availableQuantity) ||
        !event.target.value
      ) {
        setFormData((prevState: any) => ({
          ...prevState,
          [name]: event.target.value,
        }));
      }
    } else if (
      popupType === "SELL" &&
      name === "amount" &&
      row.orderOfferType === "REPO"
    ) {
      setFormData((prevState: any) => ({
        ...prevState,
        settlement: "T0",
        [name]: event.target.value,
      }));
    } else {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const handleRadio = (value: string, name: string) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const HandleSubmit = (e: any) => {
    e.preventDefault();
    let payload = {
      securityUUID: row?.securityUUID,
      orderSide: popupType,
      orderType: formData?.order_type,
      orderValidity: formData?.order_validity,
      // counterPartyUUID: formData?.counter_party,
      // counterPartyUUID: "BSA",
      settlementType: formData?.settlement ? formData?.settlement : "T0",
      orderOfferId: productLatestPrice.orderOfferId || "",
      transactionFees: formData?.transactionFees,
      totalAmount: formData?.indicativeConsideration,
    };

    if (formData?.priceOrYield === "PRICE") {
      payload = {
        ...payload,
        // @ts-ignore
        price: formData?.price
          ? parseFloat(formData?.price)
          : productLatestPrice?.unitPriceYieldOrReturnRate
          ? parseFloat(productLatestPrice?.unitPriceYieldOrReturnRate)
          : null,
        yield: formData?.yieldToDisplay
          ? parseFloat(formData?.yieldToDisplay)
          : null,
      };
    }
    if (formData?.priceOrYield === "YIELD") {
      // @ts-ignore
      payload = {
        ...payload,
        // @ts-ignore
        yield: formData?.yield ? parseFloat(formData?.yield) : formData?.yield,
        price: parseFloat(formData?.priceToDisplay),
      };
    }
    // if (["CIS", "EQUITY", "ETF"]?.includes(row?.assetClass)) {
    if (formData?.quantityOrAmount === "Quantity") {
      payload = {
        ...payload,
        // @ts-ignore
        quantity: parseInt(formData?.quantity?.replaceAll(",", "")),
        considerationWithCurrency: parseFloat(formData?.amountToDisplay),
      };
    } else if (
      formData?.quantityOrAmount === "Amount" &&
      row.orderOfferType !== "REVERSE_REPO" &&
      row.orderOfferType !== "REPO"
    ) {
      payload = {
        ...payload,
        // @ts-ignore
        quantity: parseFloat(formData?.quantityToDisplay),
        considerationWithCurrency: parseFloat(formData?.amount),
      };
    } else if (
      validateBuy &&
      formData?.quantityOrAmount === "Amount" &&
      row.orderOfferType === "REVERSE_REPO"
    ) {
      const itemSelected = counterParty.filter((item: any) => {
        if (item.value == "BSA") {
          return item.key;
        }
      });
      payload = {
        // @ts-ignore
        considerationWithCurrency: parseFloat(formData?.amount),
        counterPartyUUID:
          itemSelected.length > 0
            ? itemSelected[0].key
            : formData.counter_party,
        settlementType: formData.settlement,
        orderValidity: formData.order_validity,
        orderOfferId: productLatestPrice.orderOfferId || row.id,
        orderOfferType: row.orderOfferType,
        quantity: "N/A",
        tenor: row.tenor,
        yield: row.returnRate,
        assetClass: `${row?.tenor} Day Reverse Repo`,
        totalAmount: formData?.indicativeConsideration,
      };
    } else if (
      row.orderOfferType === "REPO" &&
      formData?.quantityOrAmount === "Amount"
    ) {
      payload = {
        // @ts-ignore
        considerationWithCurrency: parseFloat(formData?.amount),
        counterPartyUUID: formData.counter_party,
        settlementType: "T0",
        securityUUID: formData.counter_party,
        orderValidity: formData.order_validity,
        orderOfferId: productLatestPrice.orderOfferId || row.id,
        orderOfferType: row.orderOfferType,
        quantity: "N/A",
        tenor: row.tenor,
        yield: row.returnRate,
        assetClass: `${row?.tenor} Day Repo`,
        bsSecurityMediumId: counterPartyTitle,
        totalAmount: formData?.indicativeConsideration,
      };
    }
    // } else {
    //     payload = {
    //         ...payload,
    //         // @ts-ignore
    //         quantity: parseInt(formData?.quantity?.replaceAll(",", "")),
    //         considerationWithCurrency: formData?.consideration ? parseFloat(formData?.consideration) : 0,
    //     }
    // }
    if (popupType === "BUY" && row.orderOfferType != "REVERSE_REPO") {
      validateBuyTrade(
        formData?.portfolio?.key,
        row?.securityCurrency?.code,
        parseFloat(payload?.considerationWithCurrency?.toFixed(2))
      ).then((res) => {
        if (!res) {
          toast.error("Portfolio doesn't have enough balance.");
        } else {
          onSubmit(
            formData?.portfolio?.key,
            formData?.portfolio?.value,
            payload,
            productLatestPrice
          );
        }
      });
    } else {
      onSubmit(
        formData?.portfolio?.key,
        formData?.portfolio?.value,
        payload,
        productLatestPrice
      );
    }
  };

  useEffect(() => {
    setIsDisabled(true);
    let payload: any = {
      orderOfferId: productLatestPrice.orderOfferId || "",
      orderSide: popupType,
    };
    if (formData?.order_type === "MARKET") {
      if (["CIS", "EQUITY", "ETF"]?.includes(row?.assetClass)) {
        payload = {
          ...payload,
          quantity: formData?.quantity?.replaceAll(",", ""),
          settlementType: formData?.settlement,
          price: productLatestPrice?.unitPriceYieldOrReturnRate
            ? productLatestPrice?.unitPriceYieldOrReturnRate
            : "",
        };
        if (formData?.quantityOrAmount === "Amount") {
          payload = {
            ...payload,
            considerationWithCurrency: formData?.amount
              ? formData?.amount?.replaceAll(",", "")
              : "",
          };
          delete payload.quantity;
        }
      } else {
        payload = {
          ...payload,
          quantity: formData?.quantity?.replaceAll(",", ""),
          settlementType: formData?.settlement ? formData?.settlement : "T0",
          ...(!["BILL", "BOND"].includes(row?.assetClass) && {
            cleanPricePercentage: productLatestPrice?.unitPriceYieldOrReturnRate
              ? productLatestPrice?.unitPriceYieldOrReturnRate
              : "",
          }),
          ...(["BILL", "BOND"].includes(row?.assetClass) && {
            yield: productLatestPrice?.unitPriceYieldOrReturnRate
              ? productLatestPrice?.unitPriceYieldOrReturnRate
              : "",
          }),
        };
        if (
          formData?.quantityOrAmount === "Amount" &&
          row.orderOfferType !== "REVERSE_REPO"
        ) {
          payload = {
            ...payload,
            considerationWithCurrency: formData?.amount
              ? formData?.amount?.replaceAll(",", "")
              : "",
          };
          delete payload.quantity;
        }
      }
    } else {
      if (["CIS", "EQUITY", "ETF"]?.includes(row?.assetClass)) {
        payload = {
          ...payload,
          quantity: formData?.quantity?.replaceAll(",", ""),
          price: formData?.price ? parseFloat(formData?.price) : "",
          settlementType: formData?.settlement,
        };
        if (formData?.quantityOrAmount === "Amount") {
          payload = {
            ...payload,
            considerationWithCurrency: formData?.amount
              ? formData?.amount?.replaceAll(",", "")
              : "",
          };
          delete payload.quantity;
        }
      } else {
        payload = {
          ...payload,
          quantity: formData?.quantity?.replaceAll(",", ""),
          settlementType: formData?.settlement,
        };
        if (formData?.priceOrYield === "PRICE") {
          payload = {
            ...payload,
            ...(!["BILL", "BOND"].includes(row?.assetClass) && {
              cleanPricePercentage: formData?.price
                ? parseFloat(formData?.price)
                : "",
            }),
            ...(["BILL", "BOND"].includes(row?.assetClass) && {
              yield: formData?.price ? parseFloat(formData?.price) : "",
            }),
          };
          delete payload.yield;
        }
        if (formData?.priceOrYield === "YIELD") {
          payload = {
            ...payload,
            yield: formData?.yield ? parseFloat(formData?.yield) : "",
          };
          delete payload.cleanPricePercentage;
        }
        if (formData?.quantityOrAmount === "Amount") {
          payload = {
            ...payload,
            considerationWithCurrency: formData?.amount
              ? formData?.amount?.replaceAll(",", "")
              : "",
          };
          delete payload.quantity;
        }
      }
    }

    if (!["CIS", "EQUITY", "ETF"]?.includes(row?.assetClass)) {
      if (formData?.order_type === "MARKET") {
        if ((formData?.quantity || formData?.amount) && formData?.settlement) {
          if (formData?.quantity) {
            if (
              tempData?.quantity !== formData?.quantity ||
              tempData?.settlement !== formData?.settlement
            ) {
              const delayDebounceFn = setTimeout(() => {
                fetchCalculateConsideration(
                  row?.securityUUID,
                  moment().format("DD-MM-YYYY"),
                  formData?.settlement,
                  payload
                )
                  .then((res: any) => {
                    setTemp(formData);
                    setFormData((prevState: any) => ({
                      ...prevState,
                      transactionFees: res?.transactionFees,
                      consideration: res?.considerationWithCurrency,
                      amountToDisplay: res?.considerationWithCurrency,
                      quantityToDisplay: res?.quantity,
                      amount: "",
                      indicativeConsideration:
                        res?.indicativeConsiderationWithTransactionFees ||
                        res?.considerationWithCurrency,
                      yieldToDisplay: res?.yield
                        ? parseFloat(res?.yield)?.toFixed(2)
                        : "",
                      priceToDisplay: res?.cleanPricePercentage
                        ? numberWithCommasAndDecimal(
                            res?.cleanPricePercentage,
                            4
                          )
                        : "",
                    }));
                    if (popupType !== "SELL") {
                      if (parseFloat(res?.quantity) !== 0) {
                        setIsDisabled(false);
                      } else {
                        toast.error("Quantity must be greater than 0");
                      }
                    } else {
                      if (
                        parseFloat(res?.quantity) <= availableQuantity &&
                        parseFloat(res?.quantity) !== 0
                      ) {
                        setIsDisabled(false);
                      } else {
                        toast.error(
                          "Quantity must be equal or less than available quantity"
                        );
                      }
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }, 1200);

              return () => clearTimeout(delayDebounceFn);
            }
          }
          if (
            formData?.amount &&
            row.orderOfferType !== "REVERSE_REPO" &&
            row.orderOfferType !== "REPO"
          ) {
            if (
              tempData?.amount !== formData?.amount ||
              tempData?.settlement !== formData?.settlement
            ) {
              const delayDebounceFn = setTimeout(() => {
                fetchCalculateConsideration(
                  row?.securityUUID,
                  moment().format("DD-MM-YYYY"),
                  formData?.settlement,
                  payload
                )
                  .then((res: any) => {
                    setTemp(formData);
                    setFormData((prevState: any) => ({
                      ...prevState,
                      transactionFees: res?.transactionFees,
                      consideration: res?.considerationWithCurrency,
                      indicativeConsideration:
                        res?.indicativeConsiderationWithTransactionFees ||
                        res?.considerationWithCurrency,
                      quantity: "",
                      quantityToDisplay: res?.quantity,
                      yieldToDisplay: res?.yield
                        ? parseFloat(res?.yield)?.toFixed(2)
                        : "",
                      priceToDisplay: res?.cleanPricePercentage
                        ? numberWithCommasAndDecimal(
                            res?.cleanPricePercentage,
                            4
                          )
                        : "",
                    }));
                    if (popupType !== "SELL") {
                      if (parseFloat(res?.quantity) !== 0) {
                        setIsDisabled(false);
                      } else {
                        toast.error("Quantity must be greater than 0");
                      }
                    } else {
                      if (
                        parseFloat(res?.quantity) <= availableQuantity &&
                        parseFloat(res?.quantity) !== 0
                      ) {
                        setIsDisabled(false);
                      } else {
                        toast.error(
                          "Quantity must be equal or less than available quantity"
                        );
                      }
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }, 1200);

              return () => clearTimeout(delayDebounceFn);
            }
          }
        }
      } else {
        if (
          (formData?.quantity || formData?.amount) &&
          formData?.settlement &&
          (formData?.price || formData?.yield)
        ) {
          if (formData?.price) {
            if (formData.quantity) {
              if (
                tempData?.price !== formData?.price ||
                formData?.quantity !== tempData?.quantity ||
                formData?.settlement !== tempData?.settlement
              ) {
                const delayDebounceFn = setTimeout(() => {
                  fetchCalculateConsideration(
                    row?.securityUUID,
                    moment().format("DD-MM-YYYY"),
                    formData?.settlement,
                    payload
                  )
                    .then((res: any) => {
                      setTemp(formData);
                      setFormData((prevState: any) => ({
                        ...prevState,
                        transactionFees: res?.transactionFees,
                        amountToDisplay: res?.considerationWithCurrency,
                        quantityToDisplay: res?.quantity,
                        indicativeConsideration:
                          res?.indicativeConsiderationWithTransactionFees ||
                          res?.considerationWithCurrency,
                        amount: "",
                        consideration: res?.considerationWithCurrency,
                        yieldToDisplay: res?.yield
                          ? parseFloat(res?.yield)?.toFixed(2)
                          : "",
                      }));
                      if (popupType !== "SELL") {
                        if (parseFloat(res?.quantity) !== 0) {
                          setIsDisabled(false);
                        } else {
                          toast.error("Quantity must be greater than 0");
                        }
                      } else {
                        if (
                          parseFloat(res?.quantity) <= availableQuantity &&
                          parseFloat(res?.quantity) !== 0
                        ) {
                          setIsDisabled(false);
                        } else {
                          toast.error(
                            "Quantity must be equal or less than available quantity"
                          );
                        }
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }, 1200);
                return () => clearTimeout(delayDebounceFn);
              }
            }
            if (formData.amount) {
              if (
                tempData?.price !== formData?.price ||
                formData?.amount !== tempData?.amount ||
                formData?.settlement !== tempData?.settlement
              ) {
                const delayDebounceFn = setTimeout(() => {
                  fetchCalculateConsideration(
                    row?.securityUUID,
                    moment().format("DD-MM-YYYY"),
                    formData?.settlement,
                    payload
                  )
                    .then((res: any) => {
                      setTemp(formData);
                      setFormData((prevState: any) => ({
                        ...prevState,
                        transactionFees: res?.transactionFees,
                        quantity: "",
                        quantityToDisplay: res?.quantity,
                        indicativeConsideration:
                          res?.indicativeConsiderationWithTransactionFees ||
                          res?.considerationWithCurrency,
                        consideration: res?.considerationWithCurrency,
                        yieldToDisplay: res?.yield
                          ? parseFloat(res?.yield)?.toFixed(2)
                          : "",
                      }));
                      if (popupType !== "SELL") {
                        if (parseFloat(res?.quantity) !== 0) {
                          setIsDisabled(false);
                        } else {
                          toast.error("Quantity must be greater than 0");
                        }
                      } else {
                        if (
                          parseFloat(res?.quantity) <= availableQuantity &&
                          parseFloat(res?.quantity) !== 0
                        ) {
                          setIsDisabled(false);
                        } else {
                          toast.error(
                            "Quantity must be equal or less than available quantity"
                          );
                        }
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }, 1200);
                return () => clearTimeout(delayDebounceFn);
              }
            }
          }
          if (formData?.yield) {
            if (formData.quantity) {
              if (
                tempData?.yield !== formData?.yield ||
                formData?.quantity !== tempData?.quantity ||
                formData?.settlement !== tempData?.settlement
              ) {
                const delayDebounceFn = setTimeout(() => {
                  fetchCalculateConsideration(
                    row?.securityUUID,
                    moment().format("DD-MM-YYYY"),
                    formData?.settlement,
                    payload
                  )
                    .then((res: any) => {
                      setTemp(formData);
                      setFormData((prevState: any) => ({
                        ...prevState,
                        transactionFees: res?.transactionFees,
                        amountToDisplay: res?.considerationWithCurrency,
                        indicativeConsideration:
                          res?.indicativeConsiderationWithTransactionFees ||
                          res?.considerationWithCurrency,
                        quantityToDisplay: res?.quantity,
                        amount: "",
                        consideration: res?.considerationWithCurrency,
                        priceToDisplay: res?.cleanPricePercentage
                          ? numberWithCommasAndDecimal(
                              res?.cleanPricePercentage,
                              4
                            )
                          : "",
                      }));
                      if (popupType !== "SELL") {
                        if (parseFloat(res?.quantity) !== 0) {
                          setIsDisabled(false);
                        } else {
                          toast.error("Quantity must be greater than 0");
                        }
                      } else {
                        if (
                          parseFloat(res?.quantity) <= availableQuantity &&
                          parseFloat(res?.quantity) !== 0
                        ) {
                          setIsDisabled(false);
                        } else {
                          toast.error(
                            "Quantity must be equal or less than available quantity"
                          );
                        }
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }, 1200);
                return () => clearTimeout(delayDebounceFn);
              }
            }
            if (formData.amount) {
              if (
                tempData?.yield !== formData?.yield ||
                formData?.amount !== tempData?.amount ||
                formData?.settlement !== tempData?.settlement
              ) {
                const delayDebounceFn = setTimeout(() => {
                  fetchCalculateConsideration(
                    row?.securityUUID,
                    moment().format("DD-MM-YYYY"),
                    formData?.settlement,
                    payload
                  )
                    .then((res: any) => {
                      setTemp(formData);
                      setFormData((prevState: any) => ({
                        ...prevState,
                        quantity: "",
                        transactionFees: res?.transactionFees,
                        indicativeConsideration:
                          res?.indicativeConsiderationWithTransactionFees ||
                          res?.considerationWithCurrency,
                        quantityToDisplay: res?.quantity,
                        consideration: res?.considerationWithCurrency,
                        priceToDisplay: res?.cleanPricePercentage
                          ? numberWithCommasAndDecimal(
                              res?.cleanPricePercentage,
                              4
                            )
                          : "",
                      }));
                      if (popupType !== "SELL") {
                        if (parseFloat(res?.quantity) !== 0) {
                          setIsDisabled(false);
                        } else {
                          toast.error("Quantity must be greater than 0");
                        }
                      } else {
                        if (
                          parseFloat(res?.quantity) <= availableQuantity &&
                          parseFloat(res?.quantity) !== 0
                        ) {
                          setIsDisabled(false);
                        } else {
                          toast.error(
                            "Quantity must be equal or less than available quantity"
                          );
                        }
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }, 1200);
                return () => clearTimeout(delayDebounceFn);
              }
            }
          }
        }
      }
    } else {
      if (formData?.order_type === "MARKET") {
        if ((formData?.quantity || formData?.amount) && formData?.settlement) {
          if (formData?.quantity) {
            if (
              tempData?.quantity !== formData?.quantity ||
              tempData?.settlement !== formData?.settlement
            ) {
              const delayDebounceFn = setTimeout(() => {
                fetchCalculateConsideration(
                  row?.securityUUID,
                  moment().format("DD-MM-YYYY"),
                  formData?.settlement,
                  payload
                )
                  .then((res: any) => {
                    setTemp(formData);
                    setFormData((prevState: any) => ({
                      ...prevState,
                      amountToDisplay: res?.considerationWithCurrency,
                      transactionFees: res?.transactionFees,
                      indicativeConsideration:
                        res?.indicativeConsiderationWithTransactionFees ||
                        res?.considerationWithCurrency,
                      quantityToDisplay: res?.quantity,
                      amount: "",
                      yieldToDisplay: res?.yield
                        ? parseFloat(res?.yield)?.toFixed(2)
                        : "",
                      priceToDisplay: res?.cleanPricePercentage
                        ? numberWithCommasAndDecimal(
                            res?.cleanPricePercentage,
                            4
                          )
                        : "",
                    }));
                    if (popupType !== "SELL") {
                      if (parseFloat(res?.quantity) !== 0) {
                        setIsDisabled(false);
                      } else {
                        toast.error("Quantity must be greater than 0");
                      }
                    } else {
                      if (
                        parseFloat(res?.quantity) <= availableQuantity &&
                        parseFloat(res?.quantity) !== 0
                      ) {
                        setIsDisabled(false);
                      } else {
                        toast.error(
                          "Quantity must be equal or less than available quantity"
                        );
                      }
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }, 1200);

              return () => clearTimeout(delayDebounceFn);
            }
          }
          if (formData?.amount) {
            if (
              tempData?.amount !== formData?.amount ||
              tempData?.settlement !== formData?.settlement
            ) {
              const delayDebounceFn = setTimeout(() => {
                fetchCalculateConsideration(
                  row?.securityUUID,
                  moment().format("DD-MM-YYYY"),
                  formData?.settlement,
                  payload
                )
                  .then((res: any) => {
                    setTemp(formData);
                    setFormData((prevState: any) => ({
                      ...prevState,
                      quantity: "",
                      quantityToDisplay: res?.quantity,
                      transactionFees: res?.transactionFees,
                      indicativeConsideration:
                        res?.indicativeConsiderationWithTransactionFees ||
                        res?.considerationWithCurrency,
                      yieldToDisplay: res?.yield
                        ? parseFloat(res?.yield)?.toFixed(2)
                        : "",
                      priceToDisplay: res?.cleanPricePercentage
                        ? numberWithCommasAndDecimal(
                            res?.cleanPricePercentage,
                            4
                          )
                        : "",
                    }));
                    if (popupType !== "SELL") {
                      if (parseFloat(res?.quantity) !== 0) {
                        setIsDisabled(false);
                      } else {
                        toast.error("Quantity must be greater than 0");
                      }
                    } else {
                      if (
                        parseFloat(res?.quantity) <= availableQuantity &&
                        parseFloat(res?.quantity) !== 0
                      ) {
                        setIsDisabled(false);
                      } else {
                        toast.error(
                          "Quantity must be equal or less than available quantity"
                        );
                      }
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }, 1200);

              return () => clearTimeout(delayDebounceFn);
            }
          }
        }
      } else {
        if (
          (formData?.quantity || formData?.amount) &&
          formData?.settlement &&
          (formData?.price || formData?.yield)
        ) {
          if (formData?.price) {
            if (formData.quantity) {
              if (
                tempData?.price !== formData?.price ||
                formData?.quantity !== tempData?.quantity ||
                formData?.settlement !== tempData?.settlement
              ) {
                const delayDebounceFn = setTimeout(() => {
                  fetchCalculateConsideration(
                    row?.securityUUID,
                    moment().format("DD-MM-YYYY"),
                    formData?.settlement,
                    payload
                  )
                    .then((res: any) => {
                      setTemp(formData);
                      setFormData((prevState: any) => ({
                        ...prevState,
                        amountToDisplay: res?.considerationWithCurrency,
                        transactionFees: res?.transactionFees,
                        indicativeConsideration:
                          res?.indicativeConsiderationWithTransactionFees ||
                          res?.considerationWithCurrency,
                        quantityToDisplay: res?.quantity,
                        amount: "",
                        yieldToDisplay: res?.yield
                          ? parseFloat(res?.yield)?.toFixed(2)
                          : "",
                      }));
                      if (popupType !== "SELL") {
                        if (parseFloat(res?.quantity) !== 0) {
                          setIsDisabled(false);
                        } else {
                          toast.error("Quantity must be greater than 0");
                        }
                      } else {
                        if (
                          parseFloat(res?.quantity) <= availableQuantity &&
                          parseFloat(res?.quantity) !== 0
                        ) {
                          setIsDisabled(false);
                        } else {
                          toast.error(
                            "Quantity must be equal or less than available quantity"
                          );
                        }
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }, 1200);
                return () => clearTimeout(delayDebounceFn);
              }
            }
            if (formData.amount) {
              if (
                tempData?.price !== formData?.price ||
                formData?.amount !== tempData?.amount ||
                formData?.settlement !== tempData?.settlement
              ) {
                const delayDebounceFn = setTimeout(() => {
                  fetchCalculateConsideration(
                    row?.securityUUID,
                    moment().format("DD-MM-YYYY"),
                    formData?.settlement,
                    payload
                  )
                    .then((res: any) => {
                      setTemp(formData);
                      setFormData((prevState: any) => ({
                        ...prevState,
                        quantity: "",
                        transactionFees: res?.transactionFees,
                        indicativeConsideration:
                          res?.indicativeConsiderationWithTransactionFees ||
                          res?.considerationWithCurrency,
                        quantityToDisplay: res?.quantity,
                        yieldToDisplay: res?.yield
                          ? parseFloat(res?.yield)?.toFixed(2)
                          : "",
                      }));
                      if (popupType !== "SELL") {
                        if (parseFloat(res?.quantity) !== 0) {
                          setIsDisabled(false);
                        } else {
                          toast.error("Quantity must be greater than 0");
                        }
                      } else {
                        if (
                          parseFloat(res?.quantity) <= availableQuantity &&
                          parseFloat(res?.quantity) !== 0
                        ) {
                          setIsDisabled(false);
                        } else {
                          toast.error(
                            "Quantity must be equal or less than available quantity"
                          );
                        }
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }, 1200);
                return () => clearTimeout(delayDebounceFn);
              }
            }
          }
        }
      }
    }
  }, [
    formData?.quantity,
    formData?.settlement,
    formData?.price,
    formData?.yield,
    formData?.amount,
  ]);

  useEffect(() => {
    if (row?.securityUUID) {
      fetchProductLatestPrice(row?.securityUUID, {
        orderSide: popupType,
        orderOfferType:
          row.orderOfferType === "REPO" || row.orderOfferType === "REVERSE_REPO"
            ? row.orderOfferType
            : "REGULAR",
        orderOfferId: row.id,
      })
        .then((res: any) => {
          setProductLatestPrice(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [row]);

  useEffect(() => {
    counterParty?.map((item: any) => {
      if (item.key === formData.counter_party) {
        setCounterPartyTitle(item.value);
      }
    });
  }, [counterParty]);

  const allowNumber = (event: any) => {
    let charCode = event.target.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    } else {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }

    setFormData((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value
        ? parseInt(event.target.value.replace(/,/g, "")).toLocaleString()
        : "",
    }));
  };

  const getCashBalance = (data: any) => {
    if (data) {
      let value = 0;
      let currency = "";
      data?.currencyWiseBalance?.map((innerData: any) => {
        if (innerData?.currency?.symbol === row?.securityCurrency?.symbol) {
          value = innerData?.quantity;
          currency = innerData?.currency?.symbol;
        }
      });

      return value ? currency + numberWithCommasAndDecimal(value) : 0.0;
    }
  };

  // @ts-ignore
  return (
    <Dialog
      TransitionComponent={Transition}
      className={`${
        theme === 1
          ? "theme_white_popup"
          : theme === 2
          ? "theme_black_popup"
          : "theme_blue_popup"
      } ${popupType === "BUY" ? "buy_theme" : "sell_theme"}`}
      onClose={handleClose}
      open={open}
    >
      <ToastContainer />
      <DialogTitle className="buy_sell_popup__head">
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 5,
              top: 5,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/*<Slide>*/}
      <DialogContent className="buy_sell_popup__content">
        <div className="popup">
          <div className="popup_wrapper">
            <div className="popup_wrapper-header">
              <div className="_left">
                {/*<div className='popup-icon'>*/}
                {/*    <img src="/Images/Tesla.png" alt="tesla"/>*/}
                {/*</div>*/}
                <div className="popup-icon-detail">
                  <h4>
                    {row?.orderOfferType === "REVERSE_REPO"
                      ? `${row?.tenor} Day Reverse Repo`
                      : row?.orderOfferType === "REPO"
                      ? `${row?.tenor} Day Repo`
                      : row?.assetClass}
                  </h4>
                  <h4>
                    {["CIS", "EQUITY"]?.includes(row?.assetClass)
                      ? row?.bsSecuritySmallId
                      : ""}
                  </h4>
                  {row.orderOfferType === "REPO" && (
                    <p>
                      {row.orderOfferType === "REPO" && formData.counter_party
                        ? counterPartyTitle
                        : "No security selected"}
                    </p>
                  )}
                  <p>
                    {row.orderOfferType !== "REPO" && row?.bsSecurityMediumId}
                  </p>
                </div>
                {row.orderOfferType !== "REPO" &&
                  row.orderOfferType !== "REVERSE_REPO" && (
                    <div
                      className="popup-buy-switch"
                      onClick={() =>
                        setPopupType(popupType === "SELL" ? "BUY" : "SELL")
                      }
                    >
                      {popupType === "BUY" ? (
                        <>
                          <span className="badge-text">
                            {popupType === "SELL" ? "Sell" : "Buy"}
                          </span>
                          <span className="badge-circle" />
                        </>
                      ) : (
                        <>
                          <span className="badge-circle" />
                          <span className="badge-text">
                            {popupType === "SELL" ? "Sell" : "Buy"}
                          </span>
                        </>
                      )}
                    </div>
                  )}
                {row.orderOfferType === "REPO" && (
                  <div className="popup-buy-switch">
                    <span className="badge-text">{"SELL"}</span>
                    <span className="badge-circle" />
                  </div>
                )}{" "}
                {row.orderOfferType === "REVERSE_REPO" && (
                  <div className="popup-buy-switch">
                    <span className="badge-text">{"Buy"}</span>
                    <span className="badge-circle" />
                  </div>
                )}
              </div>
              <div className="_right">
                <h3>
                  {category === 1 ||
                  ["BOND", "BILL"].includes(productLatestPrice.assetClass) ||
                  row.orderOfferType === "REPO" ||
                  row.orderOfferType === "REVERSE_REPO"
                    ? `${
                        numberWithCommasAndDecimal(
                          productLatestPrice?.unitPriceYieldOrReturnRate
                        ) + "%"
                      }`
                    : `${productLatestPrice?.securityCurrency?.symbol}${
                        productLatestPrice?.unitPriceYieldOrReturnRate
                          ? numberWithCommasAndDecimal(
                              productLatestPrice?.unitPriceYieldOrReturnRate,
                              4
                            )
                          : 0
                      }`}
                </h3>
                {row.orderOfferType !== "REPO" &&
                  row.orderOfferType !== "REVERSE_REPO" && (
                    <div className="up_down">
                      {/* {productPrice?.changeInValue > 0 ? (
                                        <i className="bi bi-caret-up-fill"/>
                                    ) : (
                                        <i className="bi bi-caret-down-fill"/>
                                    )} */}
                      {/* <h4 className={productPrice?.changeInValue > 0 ? "up" : "down"}>{`${numberWithCommasAndDecimal(productPrice?.changeInValue, 4)}(${numberWithCommasAndDecimal(productPrice?.change)}%)`}</h4> */}
                    </div>
                  )}
              </div>
            </div>
            <div className="third-row">
              <div className="container">
                <div className="radio_wrapper">
                  <input
                    type="radio"
                    id="f-option"
                    checked={formData?.order_type === "MARKET"}
                    onChange={() => handleRadio("MARKET", "order_type")}
                    name="selector"
                  />
                  <label htmlFor="f-option">Market</label>
                  <div className="check" />
                </div>

                <div className="radio_wrapper">
                  <input
                    type="radio"
                    id="s-option"
                    checked={formData?.order_type === "LIMIT"}
                    onChange={() => handleRadio("LIMIT", "order_type")}
                    name="selector"
                  />
                  <label htmlFor="s-option">Limit</label>
                  <div className="check" />
                </div>
                <div className="radio_wrapper">
                  <input
                    type="radio"
                    id="h-option"
                    checked={formData?.order_type === "STOPLOSS"}
                    onChange={() => handleRadio("STOPLOSS", "order_type")}
                    name="selector"
                  />
                  <label htmlFor="h-option">Stop loss</label>
                  <div className="check" />
                </div>
              </div>
              <div className="container">
                <>
                  <div
                    className="radio_wrapper"
                    data-tip="Good till Cancelled"
                    data-for="customTool"
                    data-iscapture="true"
                  >
                    <input
                      type="radio"
                      id="GTC"
                      checked={formData?.order_validity === "GTC"}
                      onChange={() => handleRadio("GTC", "order_validity")}
                      name="selector2"
                    />
                    <label htmlFor="GTC">GTC</label>
                    <div className="check" />
                  </div>
                  <ReactTooltip
                    className="customTooltip"
                    id="customTool"
                    type="dark"
                    place="right"
                    effect="solid"
                    multiline={true}
                  />
                </>
                <>
                  <div
                    className="radio_wrapper"
                    data-tip="Good till Day"
                    data-for="customTool"
                    data-iscapture="true"
                  >
                    <input
                      type="radio"
                      id="GTD"
                      checked={formData?.order_validity === "GTD"}
                      onChange={() => handleRadio("GTD", "order_validity")}
                      name="selector2"
                    />
                    <label htmlFor="GTD">GTD</label>
                    <div className="check" />
                  </div>
                  <ReactTooltip
                    className="customTooltip"
                    id="customTool"
                    type="dark"
                    place="right"
                    effect="solid"
                    multiline={true}
                  />
                </>
              </div>
            </div>
            <div
              className={
                row.orderOfferType === "REPO" ? "second-row-repo" : "second-row"
              }
            >
              <FormControl sx={{ minWidth: "32%" }} className="form-wrapper">
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="_placeholder"
                >
                  Select Portfolio
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  name="portfolio"
                  value={formData?.portfolio}
                  className="_value"
                  onChange={(e) => {
                    handleChange(e, "portfolio");
                  }}
                >
                  {portfolioList?.map((listItems: any, index: number) => {
                    return (
                      <MenuItem value={listItems} key={index}>
                        {listItems?.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {row.orderOfferType !== "REPO" && (
                <FormControl sx={{ minWidth: "32%" }} className="form-wrapper">
                  {/*<InputLabel id="demo-simple-select-helper-label"*/}
                  {/*            className='_placeholder'>Counter Party</InputLabel>*/}
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="_placeholder"
                  >
                    BSA
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    name="counter_party"
                    value={formData.counter_party}
                    className="_value"
                    disabled={true}
                    onChange={(e) => {
                      handleChange(e, "counter_party");
                    }}
                  >
                    {/*<MenuItem value="">*/}
                    {/*    <em>BSA</em>*/}
                    {/*</MenuItem>*/}
                    {counterParty?.map((listItems: any, index: number) => {
                      return (
                        <MenuItem value={listItems?.key} key={index}>
                          {listItems?.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {row.orderOfferType === "REPO" && (
                <FormControl sx={{ minWidth: "32%" }} className="form-wrapper">
                  {/*<InputLabel id="demo-simple-select-helper-label"*/}
                  {/*            className='_placeholder'>Counter Party</InputLabel>*/}
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="_placeholder"
                  >
                    Securities
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    name="counter_party"
                    value={formData.counter_party}
                    className="_value"
                    disabled={counterParty?.length > 0 ? false : true}
                    onChange={(e) => {
                      handleChange(e, "counter_party");
                    }}
                  >
                    {/*<MenuItem value="">*/}
                    {/*    <em>BSA</em>*/}
                    {/*</MenuItem>*/}
                    {counterParty?.map((listItems: any, index: number) => {
                      return (
                        <MenuItem value={listItems?.key} key={index}>
                          {listItems?.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {row.orderOfferType !== "REPO" && (
                <FormControl sx={{ minWidth: "32%" }} className="form-wrapper">
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="_placeholder"
                  >
                    Settlement Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    name="settlement"
                    value={formData?.settlement}
                    className="_value"
                    onChange={(e) => {
                      handleChange(e, "settlement");
                    }}
                  >
                    {/*<MenuItem value="">*/}
                    {/*    <em>Select settlement</em>*/}
                    {/*</MenuItem>*/}
                    {SETTLEMENT_TYPE?.map((listItems, index: number) => {
                      return (
                        <MenuItem value={listItems?.value} key={index}>
                          {listItems?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </div>

            {["BILL", "BOND"]?.includes(row?.assetClass) && (
              <div className="price_quantity_wrapper">
                {formData?.order_type !== "MARKET" &&
                  ["BILL", "BOND"]?.includes(row?.assetClass) && (
                    <div className="price_yield_wrapper">
                      <div className="priceyield-row">
                        <div className="container">
                          <div className="radio_wrapper">
                            <input
                              type="radio"
                              id="PRICE"
                              checked={formData?.priceOrYield === "PRICE"}
                              onChange={() =>
                                handleRadio("PRICE", "priceOrYield")
                              }
                              name="selector3"
                            />
                            <label htmlFor="PRICE">Price</label>
                            <div className="check" />
                          </div>

                          <div className="radio_wrapper">
                            <input
                              type="radio"
                              id="YIELD"
                              checked={formData?.priceOrYield === "YIELD"}
                              onChange={() =>
                                handleRadio("YIELD", "priceOrYield")
                              }
                              name="selector3"
                            />
                            <label htmlFor="YIELD">Yield</label>
                            <div className="check" />
                          </div>
                        </div>
                      </div>
                      <div>
                        {formData?.priceOrYield === "PRICE" &&
                          ["CIS", "EQUITY", "ETF", "BILL", "BOND"]?.includes(
                            row?.assetClass
                          ) && (
                            <TextField
                              className="text-input"
                              id="outlined-basic"
                              type={"number"}
                              label="Price"
                              variant="outlined"
                              name="price"
                              value={formData?.price}
                              disabled={
                                !["BILL", "BOND"]?.includes(row?.assetClass) &&
                                formData?.yield
                              }
                              onChange={(e: any) => {
                                handleChange(e, "price", row?.assetClass);
                              }}
                            />
                          )}
                        {formData?.priceOrYield === "YIELD" &&
                          ["BILL", "BOND"]?.includes(row?.assetClass) && (
                            <TextField
                              id="outlined-basic"
                              type={"number"}
                              className="text-input"
                              label="Yield(%)"
                              variant="outlined"
                              name="yield"
                              disabled={
                                !["BILL", "BOND"]?.includes(row?.assetClass) &&
                                formData?.price
                              }
                              value={formData?.yield}
                              onChange={(e: any) => {
                                handleChange(e, "yield");
                              }}
                            />
                          )}
                      </div>
                    </div>
                  )}

                <div className="quantity_amount_wrapper">
                  <div className="quantityamount-row">
                    <div className="container">
                      {row.orderOfferType !== "REVERSE_REPO" &&
                        row.orderOfferType !== "REPO" && (
                          <div className="radio_wrapper">
                            <input
                              type="radio"
                              id="Quantity"
                              //    disabled={true}
                              // disabled={row.orderOfferType != "REVERSE_REPO" ? false :  true}
                              checked={
                                formData?.quantityOrAmount === "Quantity"
                              }
                              onChange={() =>
                                handleRadio("Quantity", "quantityOrAmount")
                              }
                              name="selector4"
                            />
                            <label htmlFor="Quantity">Quantity</label>
                            <div className="check" />
                          </div>
                        )}
                      <div className="radio_wrapper">
                        <input
                          type="radio"
                          id="Amount"
                          checked={formData?.quantityOrAmount === "Amount"}
                          onChange={() =>
                            handleRadio("Amount", "quantityOrAmount")
                          }
                          name="selector4"
                        />
                        <label htmlFor="Amount">Amount</label>
                        <div className="check" />
                      </div>
                    </div>
                  </div>

                  <div className="popup_wrapper-first-row-v1">
                    <div className="marketfields">
                      {["CIS", "EQUITY", "ETF"]?.includes(row?.assetClass) && (
                        <>
                          {formData?.quantityOrAmount === "Quantity" &&
                            row.orderOfferType !== "REPO" && (
                              <TextField
                                id="outlined-basic"
                                type="text"
                                className="text-input"
                                label="Quantity"
                                disabled={
                                  row.orderOfferType != "REVERSE_REPO"
                                    ? false
                                    : true
                                }
                                variant="outlined"
                                name="quantity"
                                onKeyUp={(e) => allowNumber(e)}
                                value={formData?.quantity}
                                onChange={(e: any) => {
                                  handleChange(e, "quantity");
                                }}
                              />
                            )}
                          {formData?.quantityOrAmount === "Amount" && (
                            <TextField
                              id="outlined-basic"
                              type="number"
                              className="text-input"
                              label={"Amount"}
                              variant="outlined"
                              // onKeyUp={(e) => allowNumber(e)}
                              name="amount"
                              value={formData?.amount}
                              onChange={(e: any) => {
                                handleChange(e, "amount");
                              }}
                            />
                          )}
                        </>
                      )}

                      {!["CIS", "EQUITY", "ETF"]?.includes(row?.assetClass) && (
                        <>
                          {formData?.quantityOrAmount === "Quantity" &&
                            row.orderOfferType !== "REPO" && (
                              <TextField
                                id="outlined-basic"
                                type="text"
                                className="text-input"
                                label="Quantity"
                                disabled={
                                  row.orderOfferType != "REVERSE_REPO"
                                    ? false
                                    : true
                                }
                                variant="outlined"
                                name="quantity"
                                onKeyUp={(e) => allowNumber(e)}
                                value={formData?.quantity}
                                onChange={(e: any) => {
                                  handleChange(e, "quantity");
                                }}
                              />
                            )}
                          {formData?.quantityOrAmount === "Amount" && (
                            <TextField
                              id="outlined-basic"
                              type="number"
                              className="text-input"
                              label={"Amount"}
                              variant="outlined"
                              // onKeyUp={(e) => allowNumber(e)}
                              name="amount"
                              value={formData?.amount}
                              onChange={(e: any) => {
                                handleChange(e, "amount");
                              }}
                            />
                          )}
                          {/*<TextField id="outlined-basic"*/}
                          {/*           className='text-input'*/}
                          {/*           label={formData?.order_type === "MARKET" ? "Indicative consideration" : "Consideration"}*/}
                          {/*           variant="outlined"*/}
                          {/*           name="consideration"*/}
                          {/*           disabled*/}
                          {/*           value={numberWithCommasAndDecimal(formData?.consideration) || "0"}*/}
                          {/*           onChange={(e: any) => {*/}
                          {/*               handleChange(e, "consideration")*/}
                          {/*           }}*/}
                          {/*/>*/}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {formData?.order_type !== "MARKET" &&
              ["BILL", "BOND"]?.includes(row?.assetClass) && (
                <div className="six-row">
                  {formData?.priceOrYield === "YIELD" &&
                    formData?.priceToDisplay && (
                      <p>
                        Price:{" "}
                        <span>
                          {numberWithCommasAndDecimal(
                            formData?.priceToDisplay,
                            4
                          )}
                        </span>
                      </p>
                    )}
                  {formData?.priceOrYield === "PRICE" &&
                    formData?.yieldToDisplay && (
                      <p>
                        Yield:{" "}
                        <span>
                          {numberWithCommasAndDecimal(
                            formData?.yieldToDisplay
                          ) + "%"}
                        </span>
                      </p>
                    )}
                </div>
              )}

            {!["BILL", "BOND"]?.includes(row?.assetClass) && (
              <div className="other_quantity_and_price">
                <div className="quantityamount-row">
                  <div className="container">
                    {row.orderOfferType !== "REPO" && (
                      <div className="radio_wrapper">
                        <input
                          type="radio"
                          id="Quantity"
                          disabled={
                            row.orderOfferType != "REVERSE_REPO" ? false : true
                          }
                          checked={formData?.quantityOrAmount === "Quantity"}
                          onChange={() =>
                            handleRadio("Quantity", "quantityOrAmount")
                          }
                          name="selector4"
                        />
                        <label htmlFor="Quantity">Quantity</label>
                        <div className="check" />
                      </div>
                    )}

                    <div className="radio_wrapper">
                      <input
                        type="radio"
                        id="Amount"
                        checked={formData?.quantityOrAmount === "Amount"}
                        onChange={() =>
                          handleRadio("Amount", "quantityOrAmount")
                        }
                        name="selector4"
                      />
                      <label htmlFor="Amount">Amount</label>
                      <div className="check" />
                    </div>
                  </div>
                </div>

                <div className="popup_wrapper-first-row-v1">
                  <div className="marketfields">
                    {["CIS", "EQUITY", "ETF"]?.includes(row?.assetClass) && (
                      <>
                        {formData?.quantityOrAmount === "Quantity" && (
                          <TextField
                            id="outlined-basic"
                            type="text"
                            className="text-input"
                            label="Quantity"
                            disabled={
                              row.orderOfferType != "REVERSE_REPO"
                                ? false
                                : true
                            }
                            variant="outlined"
                            name="quantity"
                            onKeyUp={(e) => allowNumber(e)}
                            value={formData?.quantity}
                            onChange={(e: any) => {
                              handleChange(e, "quantity");
                            }}
                          />
                        )}
                        {formData?.quantityOrAmount === "Amount" && (
                          <TextField
                            id="outlined-basic"
                            type="number"
                            className="text-input"
                            label={"Amount"}
                            variant="outlined"
                            // onKeyUp={(e) => allowNumber(e)}
                            name="amount"
                            value={formData?.amount}
                            onChange={(e: any) => {
                              handleChange(e, "amount");
                            }}
                          />
                        )}
                      </>
                    )}

                    {!["CIS", "EQUITY", "ETF"]?.includes(row?.assetClass) && (
                      <>
                        {formData?.quantityOrAmount === "Quantity" && (
                          <TextField
                            id="outlined-basic"
                            type="text"
                            className="text-input"
                            label="Quantity"
                            disabled={
                              row.orderOfferType != "REVERSE_REPO"
                                ? false
                                : true
                            }
                            variant="outlined"
                            name="quantity"
                            onKeyUp={(e) => allowNumber(e)}
                            value={formData?.quantity}
                            onChange={(e: any) => {
                              handleChange(e, "quantity");
                            }}
                          />
                        )}
                        {formData?.quantityOrAmount === "Amount" && (
                          <TextField
                            id="outlined-basic"
                            type="number"
                            className="text-input"
                            label={"Amount"}
                            variant="outlined"
                            // onKeyUp={(e) => allowNumber(e)}
                            name="amount"
                            value={formData?.amount}
                            onChange={(e: any) => {
                              handleChange(e, "amount");
                            }}
                          />
                        )}
                        {row.orderOfferType !== "REPO" && (
                          <TextField
                            id="outlined-basic"
                            className="text-input"
                            label={
                              formData?.order_type === "MARKET"
                                ? "Indicative consideration"
                                : "Consideration"
                            }
                            variant="outlined"
                            name="consideration"
                            disabled
                            value={
                              numberWithCommasAndDecimal(
                                formData?.consideration
                              ) || "0"
                            }
                            onChange={(e: any) => {
                              handleChange(e, "consideration");
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}

            {popupType === "SELL" && row.orderOfferType !== "REPO" && (
              <div className="available">
                Available quantity:{" "}
                {availableQuantity
                  ? numberWithCommas(availableQuantity)
                  : getCashBalance(apiData)}
              </div>
            )}
            {popupType === "BUY" && (
              <div className="available">
                Cash Balance: {getCashBalance(apiData)}
              </div>
            )}
            <div className="five-row">
              {row.orderOfferType !== "REVERSE_REPO" &&
              row.orderOfferType !== "REPO" ? (
                <div className="note">
                  <p>
                    <span>Note: </span>Quantity for this transaction is{" "}
                    <span>
                      {numberWithCommasAndDecimal(formData?.quantityToDisplay)}
                    </span>{" "}
                    with a consideration of{" "}
                    <span>
                      {numberWithCommasAndDecimal(
                        formData?.indicativeConsideration
                      )}
                    </span>
                  </p>
                </div>
              ) : (
                <p></p>
              )}
              <div className="buttons">
                <button
                  className="submit"
                  style={{ cursor: isDisabled ? "no-drop" : "pointer" }}
                  disabled={isDisabled}
                  onClick={HandleSubmit}
                >
                  Submit
                </button>
                <button className="cancel" onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      {/*</Slide>*/}
    </Dialog>
  );
};

export default BuySellPopup;
