import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {DialogContent, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {numberWithCommas, numberWithCommasAndDecimal} from "../../utils/helper";
import Glow from '@mui/material/Grow';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Glow ref={ref} {...props} />;
});

export interface OrderPreviewPopupProps {
    open: boolean;
    onClose: any;
    onEdit: any;
    theme: any;
    onSubmit: any;
    popupType: string;
    payload: any,
    row: any,
    portfolioName: string,
    productPrice: any,
}

const OrderPreviewPopup: React.FC<OrderPreviewPopupProps> = (props: OrderPreviewPopupProps) => {
    const {onEdit, onClose, open, theme, popupType, onSubmit, payload, portfolioName, row, productPrice} = props;
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog TransitionComponent={Transition}
            className={`${theme === 1 ? "theme_white_popup" : theme === 2 ? "theme_black_popup" : "theme_blue_popup"} ${popupType === "BUY" ? "buy_theme" : "sell_theme"}`}
            onClose={handleClose} open={open}>
            <DialogTitle className="buy_sell_popup__head">{onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 5,
                        top: 5,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}</DialogTitle>
            <DialogContent className="order-preview__content">
                <div className='popup'>
                    <div className='popup_wrapper'>
                        <div className='popup_wrapper-header'>
                            <div className='_left'>
                                <p>ORDER PREVIEW</p>
                                <div className='popup-buy-switch'>
                                    {popupType === "BUY" ? (
                                        <>
                                            <span className='badge-text'>Buy</span>
                                            <span className='badge-circle'/>
                                        </>
                                    ) : (
                                        <>
                                            <span className='badge-circle'/>
                                            <span className='badge-text'>Sell</span>

                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                        <div className='preview-title'>
                            <h4>{payload?.assetClass ? payload?.assetClass :  row?.assetClass}</h4>
                            <p>{payload?.bsSecurityMediumId ? payload?.bsSecurityMediumId : row?.bsSecurityMediumId}</p>
                        </div>
                        <div className='preview-first-row'>
                            <div>
                                <p>Quantity</p>
                                <h3>{typeof payload?.quantity === 'number' ? numberWithCommasAndDecimal(payload?.quantity) : payload?.quantity}</h3>
                            </div>
                            <div>
                                <p>{row.orderOfferType === 'REPO' ? 'Collatoral price' : 'Price'}</p>
                                <h3>{`${ payload?.price ? productPrice?.securityCurrency?.symbol + numberWithCommasAndDecimal(payload?.price,4) : productPrice?.unitPriceYieldOrReturnRate ? productPrice?.securityCurrency?.symbol + numberWithCommasAndDecimal(productPrice?.unitPriceYieldOrReturnRate, 4) : "-"}`}</h3>
                            </div>
                            {!["CIS", "EQUITY"]?.includes(row?.assetClass) && (
                                <div>
                                    <p>{row.orderOfferType === 'REPO' ? 'Repo rate' : 'Yield'}</p>
                                    <h3>{`${ payload?.yield ? numberWithCommasAndDecimal(payload?.yield)+"%" : "-"}`}</h3>
                                </div>
                            )}
                            <div>
                                <p>{row.orderOfferType === 'REPO' ? 'Ammount borrowed' : 'Consideration'}</p>
                                <h3>{payload?.totalAmount ? productPrice?.securityCurrency?.symbol + numberWithCommasAndDecimal(payload?.totalAmount) : payload?.considerationWithCurrency ? productPrice?.securityCurrency?.symbol + numberWithCommasAndDecimal(payload?.considerationWithCurrency) : "-"}</h3>
                            </div>
                            {row.orderOfferType !== 'REPO' && row.orderOfferType !== 'REVERSE_REPO' && <div>
                                <p>{'Transaction Fee'}</p>
                                <h3>{payload?.transactionFees ? numberWithCommasAndDecimal(payload?.transactionFees) : "-"}</h3>
                            </div>}
                        </div>
                        <div className='preview-first-row'>
                            <div>
                                <p>Portfolio</p>
                                <h3>{portfolioName}</h3>
                            </div>
                            <div>
                                <p>Settlement</p>
                                <h3>{payload?.settlementType}</h3>
                            </div>
                            {!payload?.tenor &&   <div>
                                <p>Order type</p>
                                <h3>{payload?.orderType}</h3>
                            </div>
                            }
                           <div>
                                <p>Order Amount</p>
                                <h3>{(row.orderOfferType === 'REPO' || row.orderOfferType === 'REVERSE_REPO') && payload?.considerationWithCurrency ? productPrice?.securityCurrency?.symbol + numberWithCommasAndDecimal(payload?.considerationWithCurrency) : payload.totalAmount ? productPrice?.securityCurrency?.symbol + numberWithCommasAndDecimal(+payload?.totalAmount + +payload?.transactionFees) : "-"}</h3>
                            </div>
                            {
                                payload.tenor && <div>
                                    <p>Tenor</p>
                                    <h3>{`${payload.tenor} Days`}</h3>
                                </div>
                            }
                        </div>
                        <div className='four-row'>
                            <div className="note">
                                <p><span>Note:</span> Trading fees may apply to this transaction</p>
                                <p></p>
                            </div>
                            <div className="buttons">
                                <button className="submit" onClick={onSubmit}>Confirm</button>
                                <button className="cancel" onClick={onEdit}>Edit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default OrderPreviewPopup;