import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {DialogContent, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {fetchOrderPlaceBuyOrSell, fetchRepoOrderPlaceBuyOrSell, fetchReverseRepoOrderPlaceBuyOrSell} from "../../services/marketServices";
import Glow from '@mui/material/Grow';
import { TransitionProps } from '@mui/material/transitions';
import {toast} from "react-toastify";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Glow ref={ref} {...props} />;
});

export interface OrderPreviewPopupProps {
    open: boolean;
    onClose: any;
    theme: any;
    onSubmit: any;
    popupType: string;
    payload: any;
    portfolio: string
}

const confirmPopup: React.FC<OrderPreviewPopupProps> = (props: OrderPreviewPopupProps) => {
    const {onClose, open, theme, popupType, onSubmit, payload, portfolio} = props;
    const handleClose = () => {
        onClose();
    };

    const handleSubmit = ()=>{
        if(payload.counterPartyUUID && payload.orderOfferId && payload.orderOfferType === 'REVERSE_REPO') {
            fetchReverseRepoOrderPlaceBuyOrSell(portfolio, payload).then((res) => {
                if(res?.id){
                    onSubmit();
                }
            }).catch((e)=>{
                toast.error("Facing issue while placing order.");
            })
        } else if(payload.counterPartyUUID && payload.orderOfferId && payload.orderOfferType === 'REPO') {
            fetchRepoOrderPlaceBuyOrSell(portfolio, payload).then((res) => {
                if(res?.id){
                    onSubmit();
                }
            }).catch((e)=>{
                toast.error("Facing issue while placing order.");
            })
        } else {
            delete payload.totalAmount;
            delete payload.transactionFees
            fetchOrderPlaceBuyOrSell(portfolio==="Portfolio Overview" ? "" : portfolio, payload).then((res)=>{
                if(res?.id){
                    onSubmit();
                }
            }).catch((e)=>{
                toast.error("Facing issue while placing order.");
            })
        }
    }

    return (
        <Dialog TransitionComponent={Transition}
            className={`${theme === 1 ? "theme_white_popup" : theme === 2 ? "theme_black_popup" : "theme_blue_popup"}  ${popupType === "BUY" ? "buy_theme" : "sell_theme"}`}
            onClose={handleClose} open={open}>
            <DialogTitle className="buy_sell_popup__head">{onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 5,
                        top: 5,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}</DialogTitle>
            <DialogContent className="order-preview__content">
                <div className='popup'>
                    <div className='popup_wrapper'>
                        <div className='first-row'>
                            <h4>Do you want to confirm your order?<br/> You cannot reverse this once it has been received</h4>
                        </div>
                        <div className='second-row'>
                            <div className="buttons">
                                <button className="submit" onClick={handleSubmit}>Yes</button>
                                <button className="cancel" onClick={onClose}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default confirmPopup;