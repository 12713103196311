import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment/moment";

export const makeColumns = (columns) => {
  let colDefs = columns.map((col) => {
    let colDef = col;
    if (col.prescision) {
      colDef.renderCell = (params) => (
        <NumberFormat
          thousandsGroupStyle="thousand"
          value={Number(params.value)}
          decimalSeparator="."
          displayType="text"
          type="text"
          thousandSeparator={true}
          allowNegative={true}
          decimalScale={col.prescision}
        />)
    }
    return colDef;
  })
  return colDefs;
}

const toYear = (data: any) =>{
  return data?  moment(data?.split('-')[1]+"-"+data.split('-')[0]+"-"+data.split('-')[2])?.format('YYYY') : ""
}

export const sortByField = (data: Array<any>, fieldName: string, direction: number) => {
  if(direction) {
    return fieldName === "maturityDate" || fieldName === "date"
        ? data.sort((a, b) => direction > 0 ? toYear(a[fieldName]) - toYear(b[fieldName]) :toYear(b[fieldName]) - toYear(a[fieldName]))
        : data.sort((a, b) => direction > 0 ? a[fieldName] - b[fieldName] : b[fieldName] - a[fieldName]);
  } else {
    return data;
  }
}

export const deep_value = function(obj: object, path: string){
  const pathArray = path.split('.');
  for (let i=0, len=pathArray.length; i<len; i++){
      obj = obj ? obj[pathArray[i]] : obj;
  };
  return obj;
};
