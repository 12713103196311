import {requestHeaders} from "./utils";
import {counterPartyProxyRequest} from "./proxyRequests/counterPartySearch";
import {proxyRequest} from "./proxyRequests/proxyRequest";
import {productProxyRequest} from "./proxyRequests/productProxyRequest";
import { marketGraphsProxyRequest } from "./proxyRequests/marketGraphsProxyRequest ";
import {TransactionSearchRequest} from "../types/service";

export async function fetchCounterParty(page: number, size: number, searchQuery: string) {
    let url = `/counter-party/search?page=${page}&size=${size}&searchQuery=${searchQuery}`;
    const response = await counterPartyProxyRequest(url, {
        method: 'GET',
        headers: requestHeaders,
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchOrderPlaceBuyOrSell(portfolioId: string, requestBody: any) {
    let url = portfolioId ? `/portfolio/${portfolioId}/order/place` :`/portfolio/order/place`;
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    })
    if (response.status !== 200) return [];
    return response.json();
}

export async function fetchReverseRepoOrderPlaceBuyOrSell(portfolioId: string, requestBody: any) {
    let url = `/portfolio/${portfolioId}/reverse-repo/order/place`;
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    })
    if (response.status !== 200) return [];
    return response.json();
}

export async function fetchRepoOrderPlaceBuyOrSell(portfolioId: string, requestBody: any) {
    let url = `/portfolio/${portfolioId}/repo/order/place`;
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify({
            securityUUID: requestBody.securityUUID,
            considerationWithCurrency: requestBody.considerationWithCurrency,
            counterPartyUUID: requestBody.counterPartyUUID,
            settlementType: requestBody.settlementType,
            orderValidity: requestBody.orderValidity,
            orderOfferId: requestBody.orderOfferId
}),
    })
    if (response.status !== 200) return [];
    return response.json();
}



export async function fetchCalculateConsideration(id: string, date: string, settlementType: string, requestBody: any) {
    let url = `/${id}/consideration?date=${date}&settlementType=${settlementType}`;
    const response = await productProxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    }, );
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchProductPrice(id: string, date: string) {
    let url = `/${id}/price?date=${date}`;
    const response = await productProxyRequest(url, {
        method: "POST",
    }, );
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchProductLatestPrice(id: string, requestBody: any) {
    let url = `/${id}/latest/price`;
    const response = await productProxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    }, );
    if (response.status !== 200) return {};
    return response.json();
}


export async function fetchDailyTrade(date: string) {
    let url = `/vwSecurityTrades/daily?date=${date}`;
    const response = await marketGraphsProxyRequest(url, {
        method: "GET",
        headers: requestHeaders,
    }, );
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchSecurityTrade(date: string) {
    let url = `/vwSecurityTrades/weekly?date=${date}`;
    const response = await marketGraphsProxyRequest(url, {
        method: "GET",
        headers: requestHeaders,
    }, );
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchBondBillCurveGraph(date: string, bondBillGraphsBy: string) {
    let url = `/bond-bill-graph?date=${date}&bondBillGraphsBy=${bondBillGraphsBy}`;
    const response = await marketGraphsProxyRequest(url, {
        method: "GET",
        headers: requestHeaders,
    }, );
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchDashboardAttribute(client_id: string, date: string, portfolioId?: string) {
    let url = portfolioId ? `/${client_id}/dashboard/attribute?date=${date}&portfolioId=${portfolioId}` : `/${client_id}/dashboard/attribute?date=${date}`;
    const response = await marketGraphsProxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
    });
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchBondBillTradeVolumeGraph(date: string, bondBillGraphsBy: string) {
    let url = `/bond-bill-trade-volume-graph?date=${date}&bondBillGraphsBy=${bondBillGraphsBy}`;
    const response = await marketGraphsProxyRequest(url, {
        method: "GET",
        headers: requestHeaders,
    }, );
    if (response.status !== 200) return {};
    return response.json();
}

export async function fetchOfferList(portfolioId: string, page: number, pageSize: number, requestBody: any, sort?: string) {
    let url = portfolioId ? `/order-offer/search/?portfolioId=${portfolioId}&page=${page}&size=${pageSize}&sort=${sort}` : `/order-offer/search/?page=${page}&size=${pageSize}&sort=${sort}`
    const response = await proxyRequest(url, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(requestBody),
    })
    return response.json();
}

